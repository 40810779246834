<template>
  <div
    class="outer-wrapper bg-white px-4 md:px-10 xl:px-10 py-4 flex flex-col items-center"
    :class="[
      bgConfig && bgConfig.class,
      outerMinHeight
    ]"
    :style="{
      backgroundImage: bgConfig?.image ?? ''
    }"
  >
    <div
      class="inner-wrapper flex flex-col lg:flex-row flex-1 w-full max-w-full md:max-w-wrapper-lg mx-auto items-center relative"
      :class="[innerWrapperClass]"
    >
      <div
        class="main-content relative z-[1] w-full lg:w-auto lg:max-w-[38.125rem]"
        :class="[mainContentClass]"
      >
        <component
          :is="titleTag"
          class="
            text-3.5xl leading-10
            md:text-5xl md:leading-[4rem]
            lg:text-[3.5rem] lg:leading-[4rem]
          "
          :class="[
            titleClass
          ]"
        >
          <slot name="header" />
        </component>

        <div
          v-if="$slots['sub-header']"
          class="text-lg font-normal mt-4 mb-6"
          :class="[
            subTitleClass
          ]"
        >
          <slot name="sub-header" />
        </div>

        <div v-if="$slots.default">
          <slot></slot>
        </div>

        <div 
          class="flex flex-col gap-6 items-start md:flex-row"
        >
          <atoms-button
            v-for="(button, buttonKey) in buttons"
            :id="button?.id ?? `button_${generateUID()}`"
            :key="buttonKey"
            :theme="button.theme || 'primary'"
            :to="button.url"
            :class="button?.class ?? ''"
            :cross-app="button.crossApp ?? false"
            size="large"
            v-on="{
              ...((button?.callback && {
                click: () => button.callback?.()
              }) || {})
            }"
          >
            {{ button.text }}
          </atoms-button>
        </div>
      </div>

      <div
        v-if="showImage && !imageAsBg && imageConfig.img"
        class="flex flex-1 h-full items-center"
      >
        <NuxtImg
          :src="imageConfig.img"
          :class="[
            imageConfig?.class || '',
            imageConfig?.['is-middle'] && 'lg:top-1/2 lg:-translate-y-1/2'
          ]"
          class="lg:absolute right-0 mt-6 lg:mt-0"
          :alt="imgAlt"
          :width="imageConfig.dimensions.width"
          :height="imageConfig.dimensions.height"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const { devices } = deviceChecker()

defineOptions({
  name: 'MoleculesHeroHeader'
})

const props = defineProps({
  imageConfig: {
    type: Object,
    default: () => {
      return {
        img: '',
        dimensions: {
          width: '',
          height: ''
        }
      }
    },
    required: true
  },

  imageAsBg: {
    type: Boolean,
    default: false
  },

  hideImageOnMobile: {
    type: Boolean,
    default: false
  },

  buttons: {
    type: Array,
    default: () => ([]),
    validator: arr => {
      if (arr.length === 0) {
        return true
      }

      arr.forEach(item => {
        if (!item.text) {
          return false
        }

        if (
          !item.theme ||
          ['primary', 'secondary', 'tertiary', 'link'].includes(item.theme)
        ) {
          return false
        }
      })

      return true
    }
  },

  titleTag: {
    type: String,
    default: 'h1',
    validator: value => {
      /**
       * checks if heading tag value is is from 1 - 6
       * returns false if its more than 6
       */
      if (value.indexOf('h') === 0 && value.length === 2) {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
      }

      return value
    }
  },

  outerMinHeight: {
    type: String,
    default: 'lg:min-h-[33.75rem]'
  },

  innerWrapperClass: {
    type: String,
    default: ''
  },

  mainContentClass: {
    type: String,
    default: ''
  },

  buttonsWrapperClass: {
    type: String,
    default: 'md:flex-row'
  },

  titleClass: {
    type: String,
    default: ''
  },

  subTitleClass: {
    type: String,
    default: ''
  },

  imgAlt: {
    type: String,
    default: 'Hero image'
  }
})

const isMobile = computed(() => (devices.isMobile.value || devices.isTablet.value))

const showImage = computed(() => (!(isMobile.value && props.hideImageOnMobile)))

const bgConfig = computed(() => {
  const show = showImage.value && props.imageAsBg && props.hideImageOnMobile
  return {
    ...((show && {
      image: `url(${props.imageConfig?.img})`,
      class: 'bg-center bg-cover bg-no-repeat'
    }) || {})
  }
})
</script>
